<template lang="">
  <b-card>
    <div class="row">
      <div class="col-md-6">
        <b-button
          v-if="isPrepared"
          style="margin-right: 5px;"
          variant="success"
          type="button"
          @click.prevent="showKirim"
        >
          Ajukan Rikmatek
        </b-button>
      </div>
    </div>
    <br>
    <basetable
      v-if="renderComp"
      :dataurl="dataurl"
      :fields="fields"
      :baseroute="baseroute"
      :title="title"
      :tombol_hide="true"
      :is-delete="false"
      :is-edit="false"
    >
      <template v-slot:buttonaddon />
      <template #cell(maintenance)="data">
        <span class="badge rounded-pill " :class="data.item.spb.schedules.find(x => x.variant_id == data.item.variant_id)?'bg-success':'bg-danger'">
          {{ data.item.spb.schedules.find(x => x.variant_id == data.item.variant_id)?'Sudah Dibuat':'Belum Dibuat' }}
        </span>
      </template>
      <template #cell(action)="data">
        <center>
          <b-button
            variant="flat-success"
            class="waves-effect btn-icon"
            @click="btnDetail(data.item)"
          >
            <feather-icon
              icon="FolderIcon"
            />
          </b-button>
        </center>
      </template>
    </basetable>
    <b-modal
      id="modal-center"
      ref="my-modal-kirim"
      :title="title_modal"
      centered
      hide-footer
    >
      <div class="form-group">
        <p>Apa anda yakin ingin mengajukan rikmatek ini ?</p>
      </div>

      <div class="form-group mt-2 float-right">
        <b-button
          class
          variant="primary mr-1"
          @click="hideModal"
        >
          Batal
        </b-button>
        <b-button
          variant="warning"
          @click.prevent="submitKirim"
        >
          Pengajuan Rikmatek
        </b-button>
      </div>
    </b-modal>
  </b-card>

</template>
<script>
import basetable from '@/views/base/BaseTableV4.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  BCard,
  BButton,
  BModal
} from 'bootstrap-vue'

export default {
  components: {
    basetable,
    BButton,
    BCard,
    BModal
  },
  props:{
    item_id: {
      type: String,
      default: '',
    },
    posturl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
    //   dataurl: "/vendor/25cf80d0-e573-47b1-9472-d72ee3a057a1/spb/"+this.$route.params.id,
      dataurl: "/spb/"+this.$route.params.id+"/vehicle",
      baseroute:"spb",
      title:"",
      title_modal:"",
      form_title:null,
      tombol_hide:true,
      showTable : true,
      renderComp:true,
      valid_form : null,
      id_item:null,
      fields: [
        { key: 'variant.model', label: 'Nama Barang', sortable: true },
        { key: 'number_plate', label: 'NOPOL', sortable: true },
        { key: 'year', label: 'Tahun', sortable: true },
        { key: 'color', label: 'Warna', sortable: true },
        { key: 'status', label: 'Distribusi', sortable: true },
        { key: 'maintenance', label: 'Perawatan', sortable: true },
      ],
      fields_form :{
            variant:[],
            variant_id:null,
            number_plate:null,
            year:2021,
            color:null,
            stnk_number:null,
            stnk_name:null,
            stnk_date:null,
            frame_number:null,
            machine_number:null,
      },
      isPrepared:false
    }
  },
  created(){
    this.posturl = '/spb'
  },
  mounted(){
    this.getData()
  },
  methods:{
    getData(){
        this.$http.get(this.posturl+'/'+this.$route.params.id).then(res=>{
          if(res.data.status == 'prepared')
            this.isPrepared = true
        })
     },
    btnDetail(data){
      // console.log(data)
      this.$router.push({ name: 'persiapan-unit-vendor-detail',params : { id: data.spb_id, id_vehicle:data.id, variant_id:data.variant_id} })
      // this.$router.push({ name: 'persiapan-unit-vendor-detail',params : { id: data.spb_id, id_vehicle:data.id, variant_id:data.variant_id, vh_id:data.vehicle_id} })
    },
    showKirim(){
      this.title_modal = "Konfirmasi"
      this.$refs['my-modal-kirim'].show()
    },
    hideModal(){
      this.$refs['my-modal-kirim'].hide()
    },
    submitKirim(){
      this.$http.put(this.posturl+'/'+this.$route.params.id+'/rikmatek').then(()=>{
          this.isPrepared = false
          this.getData()
          this.$refs['my-modal-kirim'].hide()
          this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Pengajuan rikmatek berhasil',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
    },
  }
}
</script>
<style lang="">

</style>
